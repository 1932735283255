import {
  Checkbox,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthService } from "../../services/auth.service";
import { OrderService } from "../../services/order.service";
import { ProductService } from "../../services/product.service";
import IOrderItem from "../../types/orderitem.type";
import { SyncShipping } from "../SyncShipping";

export const SendToday = (): JSX.Element => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  // if order data is being loaded
  const [dataLoading, setIsDataLoading] = useState(false);
  // other basic requests
  const [loading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [orderItems, setOrderItems] = useState<IOrderItem[]>([]);

  const loadOrderItems = async () => {
    setIsDataLoading(true);
    try {
      const orders = await OrderService.getOrdersToShip();
      const itemsWithProduct = orders
        .flatMap((order) => order.orderitems)
        // we only care about order items with a refurbed product
        .filter((orderItem) => !!orderItem.refurbedProduct);

      const relatedProducts = itemsWithProduct.map(
        (orderItem) => orderItem.refurbedProduct!
      );
      const products = await ProductService.getProducts(relatedProducts);
      const productMap = new Map(
        products.map((product) => [product.number, product.type])
      );

      // We are not interested in Dropshipping items
      const itemsToCollect = itemsWithProduct
        .filter(
          (orderItem) =>
            productMap.get(orderItem.refurbedProduct!)?.name !== "Dropshipping"
        )
        .sort((orderItem1, orderItem2) => {
          const name1 = orderItem1.name ?? "";
          const name2 = orderItem2.name ?? "";
          return name1.localeCompare(name2);
        });

      setOrderItems(itemsToCollect);
      setErrorMessage(undefined);
    } catch (e) {
      setErrorMessage("Error fetching order items");
      console.error("Error fetching order items", e);
    }
    setIsDataLoading(false);
  };

  useEffect(() => {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      setRedirect("/");
      return;
    }

    loadOrderItems();
  }, []);

  const onCollectedChange = async (item: IOrderItem, checked: boolean) => {
    setIsLoading(true);
    item.collectedForShipping = checked;
    await OrderService.markCollected(item.id, checked);
    setIsLoading(false);
  };

  const getServices = (orderItem: IOrderItem): string => {
    const services = [];

    if (orderItem.hydrogel) {
      services.push("H");
    }
    if (orderItem.batteryChange) {
      services.push("B");
    }

    return services.join(" + ");
  };

  const getOrderItemLocation = (orderItem: IOrderItem): string | undefined => {
    if (!orderItem.latestLocation) {
      return "<Unknown Location>";
    }

    return `${orderItem.latestLocation} (${
      orderItem.latestLocationCountry ?? "??"
    })`;
  };

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  let tableBody = (
    <TableRow
      key="loading"
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  if (!dataLoading) {
    tableBody = (
      <>
        {orderItems.map((row) => (
          <TableRow
            key={row.refurbedProduct}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.refurbedProduct}
            </TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{getOrderItemLocation(row)}</TableCell>
            <TableCell>{getServices(row)}</TableCell>
            <TableCell>
              <Checkbox
                checked={row.collectedForShipping}
                onChange={(_ev, checked) => onCollectedChange(row, checked)}
                disabled={loading}
                color="primary"
                aria-label="register"
              />
            </TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  let errorMessageElem = undefined;
  if (errorMessage) {
    errorMessageElem = (
      <div className="alert alert-danger" role="alert">
        {errorMessage}
      </div>
    );
  }

  return (
    <div>
      <div className="title-sync-grid">
        <h1>Send Today</h1>
        <SyncShipping />
      </div>
      {errorMessageElem}
      <div style={{ padding: "10px" }} />
      <Stack spacing={3}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Refurbed Product ID</b>
                </TableCell>
                <TableCell>
                  <b>Refurbed Offer</b>
                </TableCell>
                <TableCell>
                  <b>Current Location</b>
                </TableCell>
                <TableCell>
                  <b>Services</b>
                </TableCell>
                <TableCell>
                  <b>Collected</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableBody}</TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </div>
  );
};
