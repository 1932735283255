import { Component } from "react";
import { Navigate } from "react-router-dom";

import React from "react";
import { AdminService } from "../services/admin.service";
import { AuthService } from "../services/auth.service";

type Props = {};

type State = {
  redirect: string | null;
  loading: boolean;
  errorMessage: string | null;
};

export class Admin extends Component<Props, State> {
  static displayName = Admin.name;

  constructor(props: Props) {
    super(props);

    this.handleResetOrderTables = this.handleResetOrderTables.bind(this);

    this.state = {
      redirect: null,
      loading: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      this.setState({
        redirect: "/",
      });
      return;
    }
  }

  handleResetOrderTables() {
    this.setState({
      loading: true,
    });

    AdminService.resetOrderTables()
      .then((response) => {
        this.setState({
          loading: false,
          errorMessage: response,
        });
      })
      .catch((err) => this.handleError(err));
  }

  handleError(error: any) {
    console.log(error);
    this.setState({ errorMessage: error });
  }

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    return (
      <div>
        <span>
          <button
            type="submit"
            className="btn btn-outline-primary"
            disabled={this.state.loading}
            onClick={this.handleResetOrderTables}
          >
            <span>Clear Order Tables</span>
          </button>{" "}
          {this.state.loading ? (
            <span className="spinner-border spinner-border-sm"></span>
          ) : (
            <span>{this.state.errorMessage}</span>
          )}
        </span>
      </div>
    );
  }
}
