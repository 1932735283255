import { Component } from "react";
import ReactTimeAgo from "react-time-ago";

import React from "react";
import { SyncService } from "../services/sync.service";

type Props = {};

type State = {
  loading: boolean;
  message: string;
  lastSync: string | null;
};

export class SyncTesting extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      message: "",
      lastSync: null,
    };
  }

  componentDidMount() {
    SyncService.getLastMasterDataSync()
      .then((responseData) => {
        if (responseData && responseData.end) {
          this.setState({
            lastSync: responseData.end.toString(),
          });
        } else {
          this.setState({
            lastSync: null,
          });
        }
      })
      .catch((err) => this.handleError(err));
  }

  handleError(error: any) {
    this.setState({
      loading: false,
      message: "Error while sync data. Check the console",
    });
    console.log(error);
  }

  render() {
    const { message } = this.state;

    return (
      <div className="row text-end">
        <div className="row">
          {this.state.lastSync && (
            <div>
              <ReactTimeAgo
                date={Date.parse(this.state.lastSync)}
                locale="en-US"
                timeStyle="round"
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col">
            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
