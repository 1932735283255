import {
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Button } from "reactstrap";
import { AuthService } from "../../services/auth.service";
import { OrderService } from "../../services/order.service";
import IOrder from "../../types/order.type";

export const PostDataTransfer = (): JSX.Element => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  // if order data is being loaded
  const [dataLoading, setIsDataLoading] = useState(false);
  // other basic requests
  const [loading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [orders, setOrders] = useState<IOrder[]>([]);

  const loadOrders = async () => {
    setIsDataLoading(true);
    try {
      const orders = await OrderService.getOrdersShippedToday();
      setOrders(orders);
      setErrorMessage(undefined);
    } catch (e) {
      setErrorMessage("Error fetching order items");
      console.error("Error fetching order items", e);
    }
    setIsDataLoading(false);
  };

  useEffect(() => {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      setRedirect("/");
      return;
    }

    loadOrders();
  }, []);

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  let tableBody = (
    <TableRow
      key="loading"
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  if (!dataLoading) {
    tableBody = (
      <>
        {orders.map((row) => (
          <TableRow
            key={row.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.number}
            </TableCell>
            <TableCell>{row.addressLines}</TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  let errorMessageElem = undefined;
  if (errorMessage) {
    errorMessageElem = (
      <div className="alert alert-danger" role="alert">
        {errorMessage}
      </div>
    );
  }

  return (
    <div>
      <div className="title-sync-grid">
        <h1>Post Data Transfer</h1>
      </div>
      {errorMessageElem}
      <div style={{ padding: "10px" }} />
      <Stack spacing={3}>
        <Button>Create XML File</Button>
        <Button>Upload File</Button>
      </Stack>
      <Divider />
      <Stack spacing={3}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Order Number</b>
                </TableCell>
                <TableCell>
                  <b>Address</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableBody}</TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </div>
  );
};
