import axios from "axios";
import { AuthService } from "./auth.service";

const API_URL = "/api/v1.0/test";

export class TestService {
  public static async getLatestTests() {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .get(API_URL + "/latest", config)
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static async getTestById(m360id: string) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .get(API_URL + "/" + m360id, config)
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static async getTestByImei(imei: string) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .get(API_URL + "/" + imei, config)
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }

  public static async assignTestToProduct(
    productNumber: string,
    m360Id: string
  ) {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(
        API_URL + "/assignTestToProduct",
        {
          productNumber,
          m360Id,
        },
        config
      )
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }
}
