import { Form, Formik } from "formik";
import { Component } from "react";
import ReactTimeAgo from "react-time-ago";

import React from "react";
import { SyncService } from "../services/sync.service";

type Props = {};

type State = {
  loading: boolean;
  message: string;
  lastSync: string | null;
};

export class SyncShipping extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleSync = this.handleSync.bind(this);

    this.state = {
      loading: false,
      message: "",
      lastSync: null,
    };
  }

  componentDidMount() {
    SyncService.getLastOrderSync()
      .then((responseData) => {
        if (responseData && responseData.end) {
          this.setState({
            lastSync: responseData.end.toString(),
          });
        } else {
          this.setState({
            lastSync: null,
          });
        }
      })
      .catch((err) => this.handleError(err));
  }

  handleSync() {
    this.setState({
      loading: true,
    });

    SyncService.syncOrders()
      .then((responseData) => {
        let message = "";
        let lastsync = this.state.lastSync;
        if (responseData && responseData.id) {
          if (!responseData.failed && responseData.finished) {
            window.location.reload();
            return;
          } else if (responseData.failed && responseData.finished) {
            message = "Sync failed";
          } else if (!responseData.finished) {
            message = "Sync not finished";
          }
        } else if (typeof responseData === "string") {
          message = responseData;
        } else {
          message = "Invalid response from server";
        }

        this.setState({
          loading: false,
          message: message,
          lastSync: lastsync,
        });
      })
      .catch((err) => this.handleError(err));
  }

  handleError(error: any) {
    this.setState({
      loading: false,
      message: "Error while sync data. Check the console",
    });
    console.log(error);
  }

  render() {
    const { loading, message } = this.state;

    const initialValues = {
      asdf: "",
    };

    return (
      <div className="row ext-end">
        <div className="row">
          <div className="col-9">
            {this.state.lastSync && (
              <div>
                <ReactTimeAgo
                  date={Date.parse(this.state.lastSync)}
                  locale="en-US"
                  timeStyle="round"
                />
              </div>
            )}
          </div>
          <div className="col-3">
            <Formik initialValues={initialValues} onSubmit={this.handleSync}>
              <Form>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn btn-outline-primary"
                    disabled={loading}
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-repeat"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                        <path
                          fillRule="evenodd"
                          d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
