import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from "reactstrap";
import EventBus from "../common/EventBus";
import Images from "../images/images";
import { AuthService } from "../services/auth.service";
import IUserToken from "../types/usertoken.type";
import "./NavMenu.css";

type Props = {};

type State = {
  currentUserToken: IUserToken | null;
  authenticated: boolean;
  username: string | null;
  isOpen: boolean;
};

export class NavMenu extends Component<Props, State> {
  static displayName = NavMenu.name;

  constructor(props: Props) {
    super(props);
    this.logout = this.logout.bind(this);
    this.toggle = this.toggle.bind(this);

    this.state = {
      currentUserToken: null,
      authenticated: false,
      username: null,
      isOpen: false,
    };
  }

  componentDidMount() {
    const currentUser = AuthService.getCurrentUser();
    const authenticated = AuthService.isUserAuthenticated();
    this.setState({
      currentUserToken: currentUser,
      authenticated: authenticated,
      username: currentUser?.username,
    });
    EventBus.on("logout", this.logout);
  }

  componentWillUnmount() {
    EventBus.remove("logout", this.logout);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  logout() {
    AuthService.logout();
    this.setState({
      currentUserToken: null,
      authenticated: false,
      username: null,
    });
  }

  render() {
    return (
      <header>
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm ng-white mb-3"
          light
          container="md"
        >
          <NavbarBrand tag={Link} to="/">
            <img
              src="/images/mobileup.svg"
              width="50"
              height="50"
              className=""
              alt=""
            ></img>
            <span className="mx-3 bold">Operations</span>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse
            className="d-sm-inline-flex flex-sm-row-reverse"
            navbar
            isOpen={this.state.isOpen}
          >
            {this.state.authenticated && this.state.username ? (
              <Nav navbar>
                <NavItem>
                  <NavLink href="/admin">Admin ⚙</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/scanning">Scanning 🤳🏻</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/printing">
                    Print <Images.BiPrinter />
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/sendtoday">Send Today 🚚</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/shipping">
                    Shipping{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-truck"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                    </svg>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/testing">
                    Testing{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-phone"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z" />
                      <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                    </svg>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/productsearch">Product Lookup 🔍</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/productregister">Goods Receipt 📫</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/productissue">Product Issue 🔧</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/userauditing">Audit 🕵️</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="/" onClick={this.logout}>
                    Logout{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-door-open"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.5 10c-.276 0-.5-.448-.5-1s.224-1 .5-1 .5.448.5 1-.224 1-.5 1z" />
                      <path d="M10.828.122A.5.5 0 0 1 11 .5V1h.5A1.5 1.5 0 0 1 13 2.5V15h1.5a.5.5 0 0 1 0 1h-13a.5.5 0 0 1 0-1H3V1.5a.5.5 0 0 1 .43-.495l7-1a.5.5 0 0 1 .398.117zM11.5 2H11v13h1V2.5a.5.5 0 0 0-.5-.5zM4 1.934V15h6V1.077l-6 .857z" />
                    </svg>
                  </NavLink>
                </NavItem>
              </Nav>
            ) : (
              <Nav navbar>
                <NavItem>
                  <NavLink href="/login">Login</NavLink>
                </NavItem>
              </Nav>
            )}
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
