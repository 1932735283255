import axios from "axios";
import { AuthService } from "./auth.service";

const API_URL = "/api/v1.0/admin";

export class AdminService {
  public static resetOrderTables() {
    const config = {
      headers: AuthService.getAuthHeaders(),
    };
    return axios
      .post(API_URL + "/resetOrderTables", undefined, config)
      .then((response) => {
        return response.data;
      })
      .catch(console.log);
  }
}
